body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

-webkit-scrollbar {
    display: none !important;
}

* {
    -webkit-tap-highlight-color: transparent;
}

* > img {
    -webkit-tap-highlight-color: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

::-webkit-scrollbar {
    display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
* {
    box-sizing: border-box;
}
*:focus-visible {
    outline: none !important;
}

input[type='number'] {
    -moz-appearance: textfield;
}

@font-face {
    font-family: 'Inter, NotoSansThai';
    font-weight: 300;
    font-style: normal;
    src: url(../public/Assets/Fonts/Inter/Inter-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter, NotoSansThai';
    font-weight: 500;
    font-style: normal;
    src: url(../public/Assets/Fonts/Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter, NotoSansThai';
    font-weight: 400;
    font-style: normal;
    src: url(../public/Assets/Fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter, NotoSansThai';
    font-weight: 700;
    font-style: normal;
    src: url(../public/Assets/Fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    font-style: normal;
    src: url(../public/Assets/Fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    src: url(../public/Assets/Fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;
    src: url(../public/Assets/Fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    src: url(../public/Assets/Fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-style: normal;
    src: url(../public/Assets/Fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansThai';
    font-weight: 300;
    font-style: normal;
    src: url(../public/Assets/Fonts/NotoSansThai/NotoSansThai-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansThai';
    font-weight: 500;
    font-style: normal;
    src: url(../public/Assets/Fonts/NotoSansThai/NotoSansThai-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansThai';
    font-weight: 400;
    font-style: normal;
    src: url(../public/Assets/Fonts/NotoSansThai/NotoSansThai-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansThai';
    font-weight: 600;
    font-style: normal;
    src: url(../public/Assets/Fonts/NotoSansThai/NotoSansThai-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSansThai';
    font-weight: 700;
    font-style: normal;
    src: url(../public/Assets/Fonts/NotoSansThai/NotoSansThai-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'FeelingPassionate';
    font-weight: 400;
    font-style: normal;
    src: url(../public/Assets/Fonts/FeelingPassionate/FeelingPassionate-Regular.ttf) format('truetype');
}