.mbsc-fr-scroll {
    background-color: rgb(0 0 0 / 50%);
}

.mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm,
.mbsc-sel .mbsc-ltr .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    font-family: Poppins, NotoSansThai;
}

.modal.mbsc-fr-lock-ctx {
    position: fixed;
}

.mobi-custom-input .mbsc-ios.mbsc-input .mbsc-label,
.mbsc-ios.mbsc-progress .mbsc-label,
.mbsc-ios.mbsc-input input,
.mbsc-ios.mbsc-input textarea {
    font-family: Poppins, NotoSansThai;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #ccdeef;
    font-size: 16px;
    width: 100%;
    padding: 24px 16px;
    margin-top: 0px;
    border-radius: 6px;
    font-weight: 400;
    height: 50px;
    cursor: pointer;
}

.mbsc-select-input {
    color: transparent !important;
}

.mbsc-sel-filter-input {
    color: #000000 !important;
}

.mobi-custom-input .mbsc-ios.mbsc-input:before,
.mbsc-ios.mbsc-input:after,
.mbsc-ios.mbsc-form .mbsc-control-w:before,
.mbsc-ios.mbsc-form .mbsc-control-w:after {
    border: 0;
}
.mobi-custom-input label {
    font-family: Poppins, NotoSansThai;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
    padding-left: 16px;
    color: rgba(21, 56, 98, 0.5);
}

.mobi-custom-input .mbsc-fr-pointer .mbsc-fr-w .mbsc-sc-whl-gr-c,
.mbsc-fr-pointer .mbsc-fr-w .mbsc-sc-whl-gr {
    padding-top: 10px;
    padding-bottom: 10px;
}
