.mbsc-fr-scroll {
    background-color: rgb(0 0 0 / 50%);
}

.mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm,
.mbsc-sel .mbsc-ltr .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    font-family: Poppins, NotoSansThai;
}

.modal.mbsc-fr-lock-ctx {
    position: fixed;
}